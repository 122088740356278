import * as types from "../types/affiliate";

const actions = {};

// AFFILIATE
actions.getUserRequest = (data) => {
	return {
		type: types.GET_USER_REQUEST,
		payload: data,
	};
};

actions.getUserSuccess = (payload) => {
	return {
		type: types.GET_USER_SUCCESS,
		payload: payload,
	};
};

actions.getUserFail = (error) => {
	return {
		type: types.GET_USER_FAIL,
		payload: error,
	};
};

actions.setUserNull = (data) => {
	return {
		type: types.SET_USER_NULL,
		payload: data,
	};
};

export { actions };