import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const SimpleLoading = (props) => {
    const { width = "100%" , height = "150px" , justifyContent = "center", alignItems = "center" , color = "#528bab" } = props

    return <PageWrapper color={color} width={width} height={height} justifyContent={justifyContent} alignItems={alignItems}>
        <CircularProgress />
    </PageWrapper>
}

export default SimpleLoading

const PageWrapper = styled.div`
    width: ${p => p.width};
    height: ${p => p.height};
    display: flex;
    justify-content: ${p => p.justifyContent};
    align-items: ${p => p.alignItems};
    div{
        svg{
            color: ${p => p.color};
        }
    }
`