import axios from "axios";

export async function getConventionsProvider() {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_convenios/`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getConventionsById(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_convenios/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}