import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import { RenderTransition } from "../../common/RenderTransition";
import { useViewPort } from "../../../hooks/useViewPort";
import { Button } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';

const Navbar = (props) => {

    const { aside, handleAside } = props

    const layout = useSelector(state => state.layout)

    const { width } = useViewPort()

    const handleMenu = () => {
        handleAside()
    }

    const typeMenu = () => {
        if (!!aside) {
            return (<Click onClick={handleMenu}><MoreVertSharpIcon /></Click>)
        }
        else {
            return (<Click onClick={handleMenu}><ListRoundedIconFake /></Click>)
        }
    }

    return <NavbarWrapper aside={aside}>
        <Ubication>
            {width > 800 ? (
                <RenderTransition>
                    {typeMenu()}
                </RenderTransition>
            ) : null}
            <Name>{layout.pageNow}</Name>
        </Ubication>
        {width < 800 ? aside ? <Button onClick={handleMenu}>
            <MenuIcon />
        </Button> : <Button onClick={handleMenu}>
            <ClearIcon />
        </Button> : null}

    </NavbarWrapper>
}

export default Navbar;

const NavbarWrapper = styled.nav`
    width: calc( 100% - 240px);
    ${p => p.aside ? "width: calc( 100% - 80px)" : null};
    height: 80px;
    background-color: white;
    box-shadow: 7px 1px 13px 0px rgba(53,53,53,0.75);
    -webkit-box-shadow: 7px 1px 13px 0px rgba(53,53,53,0.75);
    -moz-box-shadow: 7px 1px 13px 0px rgba(53,53,53,0.75);
    position: relative;
    left: 240px;
    ${p => p.aside ? "left: 80px" : null};
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition:  .5s ease-in-out all;

    @media (max-width: 800px) {
        width: 100%;
        left: 0;
    }
`

const Ubication = styled.div`
    padding-left: 2rem;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
        padding-left: 0px;
    }
`

const Name = styled.div`
    padding-left: 2.5rem;
    color: #2f2f2f;
    font-size: 1.1rem;
    font-weight: 600;
`

const Click = styled.div`
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;
`

const ListRoundedIconFake = styled(ListRoundedIcon)`
    transform: scale(1.4);
    margin-left: .8rem;
`