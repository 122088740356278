import React, { useState } from 'react';
import styled from 'styled-components';
import SearchGeneric from '../../../common/SearchGeneric';
import TableGeneric from '../../../common/TableGeneric';

const Profesional = (props) => {

    const { profesional } = props

    const [filtered, setFiltered] = useState(null)

    const columns = [
        { id: 'profesional', label: 'Nombre' },
        { id: 'profesional_tipo_documento', label: 'Tipo DOC.' },
        { id: 'profesiona_numero_documento', label: 'Documento' },
        { id: 'profesional_matricula', label: 'Matrícula' },
    ]

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    return <Wrapper>
        <SearchGeneric title="Buscar profesionales" handleValues={handleValues} filterNames={['profesional', 'profesiona_numero_documento', 'profesional_matricula']} values={profesional} />
        <TableGeneric columns={columns} rows={filtered || profesional} pageSize={10} />;
    </Wrapper>
};

export default Profesional;

const Wrapper = styled.div`
    display: grid;
    gap: 10px;
`