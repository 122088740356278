import React, { useState } from 'react';
import styled from 'styled-components';
import SearchGeneric from '../../../common/SearchGeneric';
import TableGeneric from '../../../common/TableGeneric';

const RequestModifications = (props) => {

    const { requests } = props

    const [filtered, setFiltered] = useState(null)

    const columns = [
        { id: 'titulo', label: 'Título' },
        { id: 'fecha_solicitud', label: 'Fecha solicitud' },
        { id: 'fecha_aprobacion', label: 'Fecha aprobación' },
        { id: 'fecha_inicio_vigencia', label: 'Fecha de vigencia' },
        { id: 'motivo_rechazo', label: 'Motivo de rechazo' },
        { id: 'fecha_rechazo', label: 'Fecha de rechazo' },
        { id: 'estado_solicitud_convenio', label: 'Estado de solicitud' },
    ]

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    return <Wrapper>
        <SearchGeneric title="Buscar en las solicitudes" handleValues={handleValues} filterNames={['titulo', 'estado_solicitud_convenio']} values={requests} />
        <TableGeneric columns={columns} rows={filtered || requests} pageSize={10} />;
    </Wrapper>
};

export default RequestModifications;

const Wrapper = styled.div`
    display: grid;
    gap: 10px;
`