import React, { useEffect, useState } from "react";
import CardGeneric from "../../components/common/CardGeneric";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import styled from "styled-components";
import TableGeneric from "../../components/common/TableGeneric";

import { getConventionsProvider } from "../../redux/api/conventions";
import { ButtonGreen } from "../../components/common/ButtonsGenerics";
import TakeConventions from "../../components/templates/conventions/TakeConventions";
import SearchGeneric from "../../components/common/SearchGeneric";
import { format } from "../../helpers/FormatDate.helper";

const Conventions = () => {

    const [conventions, setConventions] = useState(null)
    const [convention, setConvention] = useState(null);
    const [pages, setPages] = useState(0);
    const [filtered, setFiltered] = useState(null)

    // TABLE
    const columns = [
        { id: 'name', label: 'Nombre' },
        { id: 'vigencia_hasta', label: 'vencimiento' },
        { id: 'vigencia', label: 'Tiempo de vigencia' },
        { id: 'vigencia_inicio', label: 'Inicio de vigencia' },
        { id: 'fecha_celebracion', label: 'Fecha de celebración' },
        { id: 'estado_convenio', label: 'Estado' },
        { id: 'acciones', label: 'Acciones' },
    ]

    useEffect(() => {
        !conventions && getConventions()
    }, [conventions]);
    

    const getConventions = async () => {
        const request = await getConventionsProvider()
        if (request.error) {
            alert("Error")
        }
        else{
            setConventions(request)
        }
    }

    const handleConventionsById = id => {
        setConvention(id)
        setPages(1)
    }

    const handleRefresh = () => {
        
    }

    const handleBackPage = () => {
        setPages(0)
    }

    const renderTable = () => {
        if (conventions) {
            let result = []
            conventions.map((row) => {
                return result.push(
                    {
                        ...row,
                        fecha_celebracion: format(row.fecha_celebracion) || '-',
                        vigencia_inicio: format(row.vigencia_inicio) || '-',
                        vigencia_hasta: format(row.vigencia_hasta) || '-',
                        acciones: <Actions>
                            <ButtonGreen onClick={() => handleConventionsById(row.id)}>Ver detalles</ButtonGreen>
                        </Actions>
                    }
                )

            })
            return result
        }
        else {
            return []
        }
    }

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    if (pages === 1) {
        return <TakeConventions id={convention} handleRefresh={handleRefresh} handleBackPage={handleBackPage}/>
    }

    return <CurrentWrapper>
        <CardGeneric title={"Consultar convenios"} icon={<AccountBalanceWalletIcon/>}>
            <SearchGeneric title="Buscar convenios" handleValues={handleValues} filterNames={['name', 'estado_convenio']} values={renderTable()} />
            <TableGeneric columns={columns} rows={filtered || renderTable()} pageSize={10} />
        </CardGeneric>
    </CurrentWrapper>
}

export default Conventions;

const CurrentWrapper = styled.div`
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`