import { InputAdornment, TextField } from '@material-ui/core';
import SearchRounded from '@material-ui/icons/SearchRounded';
import React, { useState } from 'react';
import styled from "styled-components";

const SearchGeneric = (props) => {

    const { title = "Buscar...", filterNames = [], values = [], handleValues, margin = false, type = 'text' } = props

    const [value, setValue] = useState("");

    const handleChange = e => {
        const { value } = e.target
        if (value.length > 0) handleFilter(value, filterNames, values)
        else handleValues(values)
        setValue(e.target.value)
    }

    const handleFilter = (value, filterNames, values) => {
        let filtered = []
        for (let i = 0; i < filterNames.length; i++) {
            let array = values
            const result = array.filter(item => {
                const valueBar = item[filterNames[i]]
                if (typeof valueBar === 'string') {   
                    return valueBar.toLowerCase().includes(value.toLowerCase())
                }
                else{
                    return String(valueBar).includes(value)
                }
            })
            if (result.length > 0) {
                filtered = result
                break
            }
        }
        handleValues(filtered)
        return filtered
    }

    return <SearchWrapper margin={margin}>
        <TextField
            value={value}
            onChange={handleChange}
            fullWidth
            type={type}
            size="small"
            label={title}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchRounded />
                    </InputAdornment>
                )
            }}
        />
    </SearchWrapper>;
};

export default SearchGeneric;

const SearchWrapper = styled.div`
    max-width: 350px;
    width: 100%;
    margin: ${p => p.margin};
`