import { FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SearchIcon from '@material-ui/icons/Search';
import { ButtonGreen } from "../../../common/ButtonsGenerics";
import InfoUser from "../../../common/user/InfoUser";
import { utilsActions } from "../../../../redux/actions";
import PageLoading from "../../../common/PageLoading";
import { Autocomplete } from "@material-ui/lab";
import { getDataShift, getProfesional } from "../../../../redux/api/shifts";

const FormNewTurn = props => {

    // Props
    const { handleShifts } = props
    const { family, user } = useSelector(state => state.auth)
    const members = [user, ...family]

    // State
    const [affiliate, setAffiliate] = useState(null);
    const [profesional, setProfesional] = useState(null)
    const [loading, setloading] = useState(false)
    const [profesionals, setProfesionals] = useState(null)
    const [formText, setFormText] = useState({
        profesional: null,
        speciality: null
    })

    // Form
    const [speciality, setSpeciality] = useState(null)

    // Redux
    const specialties = useSelector(state => state.utils.specialties)
    const dispatch = useDispatch()

    // Effects
    useEffect(() => {
        if (specialties === null) {
            dispatch(utilsActions.specialtiesRequest())
        }
    })

    useEffect(() => {
        if (speciality !== null) {
            getProfesionalBySpeciality(speciality)
        }
    }, [speciality])

    // Functions
    const handleChangeAffiliate = (event) => {
        setAffiliate(event.target.value);
    };

    const handleChangeProfesional = (event) => {
        setProfesional(event.target.value)
        setFormText({...formText , profesional: document.getElementById(event.target.value).firstChild.data})
    }

    const handleSubmit = async () => {
        setloading(true)
        const request = await getDataShift(speciality, profesional)
        if (request.error) {
            setloading(false)
            return
        }
        const data = {
            affiliate,
            profesional,
            speciality,
            formText
        }
        handleShifts(request, data)
        setloading(false)
    }

    const handleSpecialties = (e, value) => {
        if (value !== null) {
            setSpeciality(value.id)
            setFormText({...formText , speciality: value.name})
        }
    }

    const getProfesionalBySpeciality = async id => {
        const request = await getProfesional(id)
        if (request.error) {
            alert("Error")
            return
        }

        setProfesionals(request)
    }

    // RETURNS
    if (specialties === null) {
        return <PageLoading h="100px" />
    }

    return <FormNewTurnWrapper>
        <ColL>
            <Title>Afiliado</Title>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado</InputLabel>
                <SelectFake
                    labelId="1"
                    value={affiliate}
                    onChange={handleChangeAffiliate}
                >
                    {members.map(member => <MenuItem value={member}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            {affiliate !== null && (
                <InfoUser affiliate={affiliate} />
            )}
        </ColL>
        <ColR>
            <Title>Criterios de búsqueda</Title>
            <Autocomplete
                options={specialties}
                getOptionLabel={(option) => option.name}
                onChange={handleSpecialties}

                renderInput={(params) => <TextField {...params} value={speciality} label="Seleccione una especialidad" variant="standard" />}
            />
            {profesionals && <FormControlFake>
                <InputLabel id="1">Seleccione un profesional</InputLabel>
                <SelectFake
                    labelId="1"
                    value={profesional}
                    onChange={handleChangeProfesional}
                    disabled={profesionals?.length === 0 ? true : false}
                >
                    {profesionals.map(pro => <MenuItem id={pro.profesional_id} value={pro.profesional_id}>{`${pro.profesional_nombre} ${pro.profesional_apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>}
        </ColR>
        <Footer>
            {!loading ? (
                <ButtonGreen
                    disabled={affiliate === null || speciality === null || (profesional === null) ? true : false}
                    onClick={handleSubmit}
                >
                    Buscar
                </ButtonGreen>
            ) : (
                <PageLoading w="100%" h="auto" />
            )}

        </Footer>
    </FormNewTurnWrapper>
}

export default FormNewTurn;

const FormNewTurnWrapper = styled.div`
    display: grid;
    grid-template-areas: "left right"
                         "footer footer";
    grid-template-columns: 1fr 1fr;
    /* padding: 1rem; */
    width: 100%;
    gap: 1.2rem;
    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`

const Title = styled.div`
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #555555;
    margin-bottom: 2rem;
    @media (max-width: 800px) {
        margin-bottom: .3rem;
    }
`

const SelectFake = styled(Select)`
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const ColL = styled.div`
    grid-area: left;
`

const ColR = styled.div`
    grid-area: right;
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    display: flex;
    justify-content: center;
`