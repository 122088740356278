import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { deleteSubProvider } from '../../../../redux/api/subprovider';
import { ButtonBlue } from '../../../common/ButtonsGenerics';
import PageLoading from '../../../common/PageLoading';
import { SnackBarGeneric } from '../../../common/SnackBarGeneric';
import { Text } from '../../../common/Text';

const DeleteSubProvider = (props) => {

    const { values, handleReset } = props

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true)
        const request = await deleteSubProvider(values.id)
        if(request.error) {
            openSnackBar(
                "error",
                "¡No se pudo eliminar el subprestador!"
            );
        }
        else{
            openSnackBar(
                "success",
                "¡Se elimino correctamente!"
            );
        }
        setLoading(false)
        setTimeout(() => {
            handleReset()
        }, 1500);
    }

    return <DeleteWrapper>
        <Text color="#555555" textAlign="center" fontWeight="bold" fontSize="1.2rem">¿Desea eliminar a {values.nombre} {values.apellido}?</Text>
        <Footer>
            {!loading ? <ButtonBlue onClick={handleSubmit}>Eliminar subprestador</ButtonBlue> : <PageLoading w="100%" h="50px"/>}
        </Footer>
        <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
    </DeleteWrapper>;
};

export default DeleteSubProvider;

const DeleteWrapper = styled.div`
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    gap: 12px;
`