import React from "react";
import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ItemMenu from "./ItemMenu";
import AccordionMenu from "./AccordionMenu";
import { RenderTransition } from "../../../common/RenderTransition";
import { useSelector } from "react-redux";

const ListItemOpenMobile = () => {

    const { rol } = useSelector(state => state.auth)

    const settlement = [
        //         {
        //     name: "Solicitud medicamentos",
        //     to: "request-pharmacy"
        // },
        {
            name: "Nuevo lote",
            to: "new-settlements"
        },
        {
            name: "Lotes",
            to: "settlements"
        },
    ]

    let conventions = [
        {
            name: "Consultar convenios",
            to: "conventions"
        },

    ]
    if (rol !== 'subprestador') {
        conventions = [
            {
                name: "Consultar convenios",
                to: "conventions"
            },
            // {
            //     name: "Solicitar modificaciones",
            //     to: "historical-debt"
            // },
        ]
    }




    return (<RenderTransition>
            <ItemMenu isMobile={true} to={"/provider"} title={"Prestador"} icon={<PersonIcon />} />
            <ItemMenu isMobile={true} to={"/orders"} title={"Órdenes"} icon={<ReceiptIcon />} />
            {rol !== 'subprestador' && <AccordionMenu isMobile={true} submenus={settlement} title={"Liquidación"} icon={<LocalHospitalIcon />} />}
            {rol !== 'subprestador' && <ItemMenu isMobile={true} to={"/sub-provider"} title={"Subprestadores"} icon={<PermContactCalendarIcon />} />}
            <AccordionMenu isMobile={true} submenus={conventions} title={"Convenios"} icon={<AccountBalanceWalletIcon />} />
            <ItemMenu isMobile={true} to={"/contact"} title={"Contacto"} icon={<ContactSupportIcon />} />
    </RenderTransition>)
}

export default ListItemOpenMobile;

