import React, { useState } from "react";
import styled from "styled-components";
import LocalPharmacyRoundedIcon from '@material-ui/icons/LocalPharmacyRounded';
import CardGeneric from "../../components/common/CardGeneric";
import StockForm from "../../components/templates/pharmacy/StockForm";
import TableGeneric from "../../components/common/TableGeneric";


const StockPharmacy = () => {

    const [result, setResult] = useState(null)

    const handleResultForm = result => {
        setResult(result)
    } 

    const columns = [
        { id: 'troquel', label: 'Troquel' },
        { id: 'articulo', label: 'Artículo' },
        { id: 'presentacion', label: 'Presentacion' },
        { id: 'stock', label: 'Stock' },
    ]

    return <StockPharmacyWrapper>
        <CardGeneric title={"Stock de medicamentos"} icon={<LocalPharmacyRoundedIcon/>}>
            <StockForm handleResultForm={handleResultForm}/>
        </CardGeneric>
        {result && (
            <CardGeneric title={"Resultados"} icon={<LocalPharmacyRoundedIcon/>}>
                <TableGeneric columns={columns} rows={result} pageSize={10}/>
            </CardGeneric>
        )}
    </StockPharmacyWrapper>
}

export default StockPharmacy;

const StockPharmacyWrapper = styled.div`
    width: 100%;
`