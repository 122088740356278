import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';



export default function TableGeneric(props) {
  const { rows, columns, pageSize, footer = true} = props

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <PaperFake>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.label.toUpperCase() === 'TOTAL A LIQUIDAR' || column.label.toUpperCase() === 'IMPORTE' || column.label.toUpperCase() === 'HONORARIOS MÉDICOS' || column.label.toUpperCase() === 'AYUDANTE' || column.label.toUpperCase() === 'GASTOS' || column.label.toUpperCase() === 'MODULOS' || column.label.toUpperCase() === 'VALOR TOTAL' ? 'right' : column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label.toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const label = column.label.toUpperCase()
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={label === 'TOTAL A LIQUIDAR' || label === 'IMPORTE' || label === 'IMPORTE TOTAL' || label === 'HONORARIOS MÉDICOS' || label === 'AYUDANTE' || label === 'GASTOS' || label === 'MODULOS' || label === 'VALOR TOTAL' ? 'right' : column.align}>
                        <Span>{column.format && typeof value === 'number' ? column.format(value) : value}</Span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!!footer && <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={"Filas por página"}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />}
    </PaperFake>
  );
}

const Span = styled.span`
  color: black;
  font-weight: bolder;
`
const PaperFake = styled(Paper)`
  position: relative;
  /* width: calc(100% - 2rem); */
  overflow-x: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`