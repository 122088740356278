import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

// Reducers
import { auth } from "./reducers/auth";
import { layout } from "./reducers/layout";
import { affiliate } from "./reducers/affiliate";
import { utils } from "./reducers/utils";


// Sagas
import { authWatcher } from "./sagas/auth";
import { affiliateWatcher } from "./sagas/affiliate";
import { utilsWatcher } from "./sagas/utils";



export const rootReducer = combineReducers({
	auth: auth,
	affiliate: affiliate,
	layout: layout,
	utils: utils,
});

export function* rootSaga() {
	yield all([
		authWatcher(),
		affiliateWatcher(),
		utilsWatcher()
	]);
}
