import React from "react";
import styled from "styled-components";
import { ButtonGreen } from "./ButtonsGenerics";

const CardGeneric = (props) => {
    const { icon, title, handleClick = false, button: Button = false, buttonName = false } = props

    return <CardWrapper>
        <Icon>{icon}</Icon>
        <CardHeader>
            {title}
            {buttonName !== false ? <Click><ButtonGreen onClick={handleClick}>{buttonName}</ButtonGreen></Click>: null}
        </CardHeader>
        <CardBody>
            {props.children}
        </CardBody>
    </CardWrapper>
}

export default CardGeneric;

const CardWrapper = styled.div`
    background-color: white;
    width: 100%;
    min-height: 100px;
    height: auto;
    border-radius: 3px;
    box-shadow: 0px 0px 8px 0px rgba(127,127,127,0.75);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(127,127,127,0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(127,127,127,0.75);
    position: relative;
    margin-bottom: 2rem;
`

const Icon = styled.div`
    width: 60px;
    height: 60px;
    background-color: #3e7ea0;
    border-radius: 3px;
    position: absolute;
    top: -15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0px 0px 6px 0px rgba(127,127,127,0.75);
    -webkit-box-shadow: 0px 0px 6px 0px rgba(127,127,127,0.75);
    -moz-box-shadow: 0px 0px 6px 0px rgba(127,127,127,0.75);
    @media (max-width: 800px) {
        width: 50px;
        height: 50px;
    }
    
`

const Click = styled.div`
    margin: 1rem;
    margin-top: 2rem;
    margin-right: 3rem;
    button{
        text-transform: uppercase;
        @media (max-width: 800px) {
            font-size: .8rem;
        }
    }
`

const CardHeader = styled.div`
    width: calc(100% - 6rem);
    height: 40px;
    padding-left: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    @media (max-width: 800px) {
        font-size: 1rem;
        font-weight: 600;
    }
`
const CardBody = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
    gap: 10px;
    display: grid;
`