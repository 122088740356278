import axios from "axios";

export async function getSubProviders() {
	try {
		const req = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/profesional_prestadores/`);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function deleteSubProvider(id) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/profesional_prestadores/delete/${id}`);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function createSubProvider(body) {
	try {
		const req = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/profesional_prestadores/`, body);
		return req.data
	} catch (error) {
		return {error}
	}
}