import * as types from "../types/affiliate";

const initialState = {
	affiliate: null,
	loading: false,
	error: null,
};

export const affiliate = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_USER_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case types.GET_USER_SUCCESS:
			return {
				...state,
				loading: false,
				affiliate: action.payload,
			};
		case types.GET_USER_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case types.SET_USER_NULL:
			return {
				...state,
				loading: false,
				affiliate: null,
			};
		default:
			return state;
	}
};
