import axios from "axios";

export async function getOrders() {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getOrdersByDate(id, {initial_date, finish_date}) {
	try {
        const body = {
            fecha_desde: initial_date,
            fecha_hasta: finish_date
        }
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador/search`, body);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function getOrderById(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/orden/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function deleteOrderById(message, id) {
	try {
        const body = {
            motivo: message
        }
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador/anular_orden/${id}`, body);
        return response.data
    } catch (error) {
        return {error}
    }
}


export async function createOrder(body) {
	try {
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador`, body);
        return response.data
    } catch (error) {
        return { error }
    }
}

export async function consumeOrder(id, codigo) {
	try {
        const body = {
            id,
            codigo
        }
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/orden_prestador/consumir_orden`, body);
        return response.data
    } catch (error) {
        return { error }
    }
}

export async function getPlansService(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_afiliado/planes/${id}`);
        return response.data
    } catch (error) {
        return {error}
    }
}