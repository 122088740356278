import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from './Text';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';


const InputFile = props => {

    const { handleB64File } = props

    const [fileState, setFileState] = useState([])

    const handleChange = async e => {
        let files = []
        let filesStateLocal = []
        if (e.target.files.length > 5) {
            alert('5 archivos máximo')
            return
        }
        for (let index = 0; index < e.target.files.length; index++) {
            let file = e.target.files[index]
            if (file.size >= 5048576) {
                alert('Tamaño máxido 5mb')
                break
            }
            let size = formatSizeUnits(file.size)
            if (file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/png") {
                setFileState([...filesStateLocal, {
                    name: file.name,
                    size: size
                }])
            }
            else {
                alert('Formato no válido')
                setFileState(null)
                break
            }
            filesStateLocal.push({ name: file.name, size })
            const fileTransform = await toBase64(file)
            files.push({ image: fileTransform, image_name: file.name })
        }
        handleB64File(files || [])
    }

    const formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1) { bytes = bytes + " bytes"; }
        else if (bytes === 1) { bytes = bytes + " byte"; }
        else { bytes = "0 bytes"; }
        return bytes;
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    return <InputFileWrapper>
        <LabelInput for="file">
            {/* Si el input esta vacio */}
            {fileState.length === 0 && (
                <>
                    <ButtonBlueFake>Seleccionar archivo</ButtonBlueFake>
                    <Text fontSize=".8rem" color="#444444">PDF, JPG, JPEG, PNG</Text>
                </>
            )}
            {/* Si contiene algo */}
            {!!fileState && <Files>
                {(
                    fileState.map(item => (<div>
                        <ButtonBlueFake><InsertDriveFileIcon /> {item.name}</ButtonBlueFake>
                        <Text fontSize=".8rem" color="#444444" textAlign="center">{item.size}</Text>
                    </div>))
                )}
            </Files>}
        </LabelInput>
        <File
            onChange={handleChange}
            id="file"
            type="file"
            multiple
        />
    </InputFileWrapper>
}

export default InputFile;

const InputFileWrapper = styled.div`
    padding: 1rem;
    margin: 1rem 0;
    width: calc(100% - 4rem);
`

const File = styled.input`
    display: none;
`

const LabelInput = styled.label`
    display: flex;
    width: 100%;
    max-width: 100%;
    min-height: 100px;
    height: auto;
    border: 2px #528bab dashed;
    padding: .5rem 1rem;
    color: #528bab;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s ease-in-out all;
    border-radius: 3px;
    flex-direction: column;
    :hover{
        background-color: #f0f0f0;
    }
`
const ButtonBlueFake = styled.div`
    padding: .7rem 1rem;
    background-color: #528bab;
    border-radius: 5px;
    color: #fff;
    margin-bottom: .2rem;
    width: auto;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
`

const Files = styled.div`
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    gap: 5px;
`