import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search';
import { searchAffiliate } from '../../../../redux/api/affiliate';
import PageLoading from '../../../common/PageLoading';
import { Autocomplete } from '@material-ui/lab';
import { Text } from '../../../common/Text';
import { createOrder, getPlansService } from '../../../../redux/api/orders';
import InputFile from '../../../common/InputFile';
import { ButtonGreen, ButtonRed } from '../../../common/ButtonsGenerics';
import { getSubProviders } from '../../../../redux/api/subprovider';
import { getProviders, getValuesProviders } from '../../../../redux/api/provider';
import { SnackBarGeneric } from '../../../common/SnackBarGeneric';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { formatCurrency } from '../../../../helpers/currency.format.helper';

const OrderForm = () => {

    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [affiliates, setAffiliates] = useState(null)
    const [affiliate, setAffiliate] = useState(null)
    const [plans, setPlans] = useState(null)
    const [plan, setPlan] = useState(null)
    const [programs, setPrograms] = useState(null)
    const [program, setProgram] = useState(null)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [values, setValues] = useState([])
    const [providers, setProviders] = useState(null)
    const [provider, setProvider] = useState(null)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const handleSearch = async () => {
        setAffiliates(null)
        setLoading(true)
        const request = await searchAffiliate(search)
        if (request.error) {
            setAffiliates(null)
            openSnackBar(
                "error",
                "¡Ocurrió un error al buscar los afiliados!"
            );
        }
        else {
            setAffiliates(request)
        }
        setLoading(false)
    }

    const handleSearchKey = e => {
        if (e.keyCode === 13) {
            handleSearch()
        }
    }

    const handleAffiliate = (_, value) => {
        if (value !== null) {
            setAffiliate(value)
            getPlans(value.id)
        }
    }

    const getPlans = async (id) => {
        const request = await getPlansService(id)
        if (!request.error) {
            setPlans(request)
        }
        else {
            openSnackBar(
                "error",
                "¡No se pudieron obtener los planes!"
            );
        }
    }

    const handlePlans = e => {
        setPlan(e.target.value)
        handleProviders(e.target.value)
        setProviders(null)
        setValues([])
        setProvider(null)
    }

    const getPrograms = async () => {
        const request = await getSubProviders()
        if (request.error) {
            openSnackBar(
                "error",
                "¡No se pudieron obtener los profesionales!"
            );
        }
        else {
            setPrograms(request)
        }
    }

    const handleProviders = async (plan_id) => {
        const body = {
            afiliado_id: affiliate.id,
            plan_id: plan_id
        }
        const request = await getProviders(body)
        if (request.error) {
            openSnackBar(
                "error",
                "¡No se pudieron obtener los prestadores!"
            );
        }
        else {
            setProviders(request)
            getPrograms()
        }
    }

    const handleB64File = (file, name) => {
        setFile(file)
        setFileName(name)
    }

    const handlePrograms = e => {
        setProgram(e.target.value)
    }

    const handleValuesProvider = async id => {
        const request = await getValuesProviders(affiliate.id, plan, id)
        if (request.error) {
            openSnackBar(
                "error",
                "¡Ocurrió un error al seleccionar el prestador!"
            );
        }
        else {
            const isExist = values.find(item => item.prestacion_id === request.prestacion_id)
            if (!!isExist) {
                openSnackBar(
                    "warning",
                    "¡Ya esta en uso esta prestación!"
                );
            }
            else {
                setValues([...values, request])
            }
        }
    }

    const handleSubmit = async () => {
        setLoadingSubmit(true)
        const body = {
            afiliado_id: affiliate.id || 0,
            profesional_id: program,
            plan_id: plan || 0,
            image: file,
            image_name: fileName,
            prestacion_ids: values.map(item => ({ cantidad: parseInt(item.cantidad, 10) || 1, id: item.prestacion_id }))
        }
        const request = await createOrder(body)
        setLoadingSubmit(false)
        if (request.error) openSnackBar(
            "error",
            "¡No se pudo emitir la orden!"
        );
        else {
            openSnackBar(
                "success",
                "¡Orden emitida correctamente!"
            )
            setAffiliates(null)
            setAffiliate(null)
            setPlans(null)
            setPlan(null)
            setPrograms(null)
            setProgram(null)
            setFile(null)
            setFileName(null)
            setValues([])
            setProviders(null)
            setProvider(null)
        }
    }

    const handleChangeProv = (_, value) => {
        setProvider(value.prestacion_id)
        handleValuesProvider(value.prestacion_id)
    }

    const handleDeleteValue = id => {
        setValues(values.filter(item => item.prestacion_id !== id))
    }

    const handleChangeQuantity = (id, value) => {
        const result = values.map(item => {
            if (id === item.prestacion_id) {
                return {
                    ...item,
                    cantidad: value
                }
            } else {
                return item
            }
        })
        setValues(result)
    }

    return (
        <OrderFormWrapper>
            <Text textSize="16px" color="#555555" fontWeight="700">BUSCAR AFILIADO</Text>
            <Grid>
                <Search>
                    <TextField onKeyUp={handleSearchKey} value={search} onChange={(e) => setSearch(e.target.value)} variant='outlined' fullWidth label="Buscar afiliado (nombre, documento)" />
                    <ButtonFake onClick={handleSearch} color="primary">
                        {!loading ? <SearchIcon /> : <PageLoading scale="0.5" h="10px" />}
                    </ButtonFake>
                    
                </Search>
                {!!affiliates ? (
                    <Autocomplete
                        options={affiliates}
                        getOptionLabel={(option) => option.name}
                        onChange={handleAffiliate}
                        renderInput={(params) => <TextField {...params} value={affiliate} label="Seleccione un afiliado" variant="outlined" />}
                    />)
                    :
                    (<TextCenter textSize="16px" color="#555555" textAlign="center" fontWeight="700">Sin afiliados</TextCenter>)
                }
            </Grid>

            <Grid>
                {!!plans && (
                    <div>
                        <TextFake textSize="16px" color="#555555" fontWeight="700">SELECCIONE UN PLAN</TextFake>
                        <FormControlFake fullWidth>
                            {/* <InputLabel id="1">Seleccione el plan</InputLabel> */}
                            <Select
                                variant="outlined"
                                labelId="1"
                                value={plan}
                                onChange={handlePlans}
                                placeholder="Planes"
                            >
                                {plans.map(plan => <MenuItem value={plan.id}>{plan.name}</MenuItem>)}
                            </Select>
                        </FormControlFake>
                    </div>
                )}
                {!!providers && (
                    <div>
                        <TextFake textSize="14px" color="#555555" fontWeight="700">SELECCIONE UNA PRESTACIÓN</TextFake>
                        <Autocomplete
                            options={providers}
                            getOptionLabel={(option) => option.prestacion_name}
                            onChange={handleChangeProv}
                            renderInput={(params) => <TextField {...params} value={provider} label="Seleccione una prestacion" variant="outlined" />}
                        />
                        {/* <FormControl fullWidth>
                            <Select
                                variant="outlined"
                                labelId="1"
                                value={provider}
                                onChange={handleChangeProv}
                                placeholder="Planes"
                                disabled={!providers || providers?.length === 0 ? true : false}
                            >
                                {providers.map(pro => <MenuItem value={pro.prestacion_id}>{pro.prestacion_name}</MenuItem>)}
                            </Select>
                        </FormControl> */}
                    </div>
                )}
            </Grid>
            {!!values && values.length > 0 && (<div>
                <div>
                    <Header>
                        <Text color="#333333" fontWeight="bold">Nombre prestación</Text>
                        <Text color="#333333" fontWeight="bold">Descuento damsu</Text>
                        <Text color="#333333" fontWeight="bold">Importe afiliado</Text>
                        <Text color="#333333" fontWeight="bold">Total</Text>
                        <Text color="#333333" fontWeight="bold">Cantidad</Text>
                        <Text color="#333333" fontWeight="bold">Acción</Text>
                    </Header>
                    {values.map(item => {
                        return <Body>
                            <Text color="#333333" >{item.prestacion_name}</Text>
                            <Text color="#333333" >%{item.porcentaje_descuento_damsu}</Text>
                            <Text color="#333333" >${formatCurrency(item.importe_a_cargo_afiliado?.toFixed(2))}</Text>
                            <Text color="#333333" >${formatCurrency(item.valor_total.toFixed(2))}</Text>
                            <Text color="#333333" ><TextField type='number' value={item.cantidad || 1} onChange={(e) => handleChangeQuantity(item.prestacion_id, e.target.value)} /></Text>
                            <Text color="#333333" ><ButtonRed onClick={() => handleDeleteValue(item.prestacion_id)}>Quitar</ButtonRed></Text>
                        </Body>
                    })}
                </div>
            </div>)}
            <br />
            <Grid>
                {!!programs && (
                    <div>
                        <TextFake textSize="14px" color="#555555" fontWeight="700">SELECCIONE UN PROFESIONAL</TextFake>
                        <FormControl fullWidth>
                            {/* <InputLabel id="1">Seleccione el plan</InputLabel> */}
                            <Select
                                variant="outlined"
                                labelId="1"
                                value={program}
                                onChange={handlePrograms}
                                placeholder="Planes"

                            >
                                {programs.map(pro => <MenuItem value={pro.id}>{pro.nombre} {pro.apellido}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                )}

            </Grid>

            {!!program && (
                <>
                    <Text textSize="14px" color="#555555" fontWeight="700">ARCHIVO ADJUNTO (opcional)</Text>
                    <InputFile handleB64File={handleB64File} />
                </>
            )}
            <Footer>
                {!loadingSubmit ? 
                    <ButtonGreen onClick={handleSubmit} disabled={!plan || !program || !plan || !provider ? true : false}>Emitir orden</ButtonGreen>
                    : <PageLoading h="60px" />
                }
            </Footer>
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </OrderFormWrapper>
    )
}

export default OrderForm

const OrderFormWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const Search = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
`

const Footer = styled.div`
    height: 40px;
    margin: 12px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`

const TextFake = styled(Text)`
    margin-bottom: 10px;
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr .3fr 1fr;
    row-gap: 10px;
    text-align: center;
`

const Body = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr .3fr 1fr;
    padding: 10px 0;
    text-align: center;
`

const ButtonFake = styled.div`
    height: 52px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 4px;
    cursor: pointer;
    :hover{
        border: 1px solid #222222;
    }
`

const TextCenter = styled(Text)`
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FormControlFake = styled(FormControl)`
    max-width: 85vw;
`