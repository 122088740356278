import * as types from "../types/utils";

const actions = {};

// ESPECIALIDADES
actions.specialtiesRequest = (data) => {
	return {
		type: types.GET_SPECIALTIES_REQUEST,
		payload: data,
	};
};

actions.specialtiesSuccess = (payload) => {
	return {
		type: types.GET_SPECIALTIES_SUCCESS,
		payload: payload,
	};
};

actions.specialtiesFail = (error) => {
	return {
		type: types.GET_SPECIALTIES_FAIL,
		payload: error,
	};
};

export { actions };