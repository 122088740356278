import * as types from "../types/utils";
import { call, put, takeEvery } from "redux-saga/effects";
import { getSpecialties } from "../api/utils";
import { utilsActions } from "../actions";


function* getRequest(action) {
	try {
		const res = yield call(getSpecialties, action.payload);
		yield put(
			utilsActions.specialtiesSuccess(res.data)
		);
	} catch (error) {
		yield put(utilsActions.specialtiesFail(error));
	}
}


function* utilsWatcher() {
	yield takeEvery(types.GET_SPECIALTIES_REQUEST, getRequest);
}

export { utilsWatcher };