import * as types from "../types/utils";

const initialState = {
	specialties: null,
	specialtiesLoading: false,
	specialtiesError: null,
};

export const utils = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_SPECIALTIES_REQUEST:
			return {
				...state,
                specialtiesLoading: true,
                specialtiesError: null,
			};
		case types.GET_SPECIALTIES_SUCCESS:
			return {
				...state,
				specialtiesLoading: false,
				specialties: action.payload,
			};
		case types.GET_SPECIALTIES_FAIL:
			return {
				...state,
				specialtiesLoading: false,
				specialtiesError: action.payload,
			};
		default:
			return state;
	}
};
