import React, { useEffect, useState } from 'react';
import CardGeneric from '../../components/common/CardGeneric';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { Button } from '@material-ui/core';
import TableGeneric from '../../components/common/TableGeneric';
import DialogGeneric from '../../components/common/DialogGeneric';
import CreateSubProvider from '../../components/ui/organisms/subprovider/CreateSubProvider';
import { useSnackBar } from '../../hooks/useSnackBar';
import { SnackBarGeneric } from '../../components/common/SnackBarGeneric';
import { getSubProviders } from '../../redux/api/subprovider';
import styled from 'styled-components';
import { ButtonRed } from '../../components/common/ButtonsGenerics';
import PageLoading from '../../components/common/PageLoading';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteSubProvider from '../../components/ui/organisms/subprovider/DeleteSubProvider';
import SearchGeneric from '../../components/common/SearchGeneric';

const Subprovider = () => {

    const columns = [
        { id: 'name', label: 'Nombre' },
        { id: 'tipo_documento', label: 'Tipo doc' },
        { id: 'numero_documento', label: 'Documento' },
        { id: 'matricula', label: 'Matricula' },
        { id: 'especialidad_principal', label: 'Especialidad' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const [closeDialog, setCloseDialog] = useState(false)
    const [show, setShow] = useState(false);
    const [providers, setProviders] = useState(null);
    const [filtered, setFiltered] = useState(null)

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    useEffect(() => {
        !providers && getProvider()
    }, [providers]);

    const handleShow = () => {
        setShow(!show)
    }

    const handleReset = () => {
        setShow(false)
        setProviders(null)
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
    }

    const getProvider = async () => {
        const request = await getSubProviders()
        console.log("1",request);
        if (request.error) {
            if (request?.error?.response?.status === 404) {
                openSnackBar(
                    "error",
                    "¡No existen subprestadores!"
                );
                tableRender([])
                return
            }
            else{
                console.log("else");
                openSnackBar(
                    "error",
                    "¡Error al traer los subprestadores!"
                );
                return
            }
        }
        else {
            tableRender(request)
        }
    }

    const tableRender = providers => {
        let result = []
        if (providers) {
            result = providers.map(provider => {
                return {
                    ...provider,
                    especialidad_principal: provider.especialidad_principal || 'No especificado',
                    name: provider?.nombre + ' ' + provider?.apellido,
                    acciones: <Actions>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonRed><DeleteForeverIcon/></ButtonRed>} component={<DeleteSubProvider handleReset={handleReset} values={provider}/>}/>
                    </Actions>,
                }
            })
        }
        setProviders(result)
        return result
    }

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    if(!providers) {
        return <>
            <PageLoading w="100%" h="100px" />
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </>
    }

    return <div>
        <CardGeneric title={"Subprestadores"} icon={<EventNoteIcon />} handleClick={handleShow} button={Button} buttonName={"Crear subprestador"}>
            <SearchGeneric title="Buscar subprestador" handleValues={handleValues} filterNames={['name', 'especialidad_principal', 'matricula', 'numero_documento']} values={providers} />
            <TableGeneric columns={columns} rows={filtered || providers} pageSize={10} />
            
            <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} handleShow={handleShow} show={show} button={true} component={<CreateSubProvider handleReset={handleReset} handleForceClose={handleForceClose} />} />
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </CardGeneric>
    </div>;
};

export default Subprovider;

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`