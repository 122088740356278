import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { layoutActions } from "../../../redux/actions";
import Aside from "../aside/Aside";
import Navbar from "../navbar/Navbar";
import bg from "../../../assets/images/fondo2.png";
import AsideMobile from "../aside/AsideMobile";

const Layout = (props) => {
	// REDUX
	const menu = useSelector(state => state.layout.modeMenu)
	const isMobile = useSelector(state => state.layout.mobileMenu)
	const dispatch = useDispatch()
	
	const handleAside = () => {
		dispatch(layoutActions.setMenuMode(!menu))
	}

	if (menu === null) {
		return null
	}
	// RETURN
	return (
		<>
            <AsideContainer>
				{!isMobile ? (
                	<Aside aside={!menu} handleAside={handleAside}/>
				) : (
					<AsideMobile aside={menu} handleAside={handleAside}/>
				)}
            </AsideContainer>
            <Navbar aside={isMobile ? !menu : menu} handleAside={handleAside}/>
			<Main bg={bg} aside={isMobile ? !menu : menu} >
				{props.children}
			</Main>
		</>
	);
};

export default Layout;


const Main = styled.main`
	padding: 2rem 2rem 2rem calc(2rem + 260px);
	${p => p.aside ? "padding: 2rem 2rem 2rem calc(2rem + 80px);" : null};
	overflow: ${(props) => props.menu && "hidden"};
	transition: .5s ease-in-out all;
	background-image: url(${bg});
	min-height: calc(100vh - 160px);
	height: auto;

	@media (max-width: 768px) {
		padding: 3rem 1rem 1rem 1rem;
	}
`;

const AsideContainer = styled.div`
    position: fixed;
    left: 0;
	@media (max-width: 768px) {
		z-index: 10000;
	}
`