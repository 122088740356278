import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { layoutActions } from "../redux/actions";

export const useViewPort = () => {

    const [width, setWidth] = useState(window.screen.width)
    const [height, setHeight] = useState(window.screen.height)

    const dispatch = useDispatch()

    useEffect(() => {
        if (width < 800) {
            dispatch(layoutActions.setMenuMobile(true))
        }
        else{
            dispatch(layoutActions.setMenuMobile(false))
        }
    }, [width])

    window.addEventListener('resize', () => {
        setWidth(window.screen.width)
        setHeight(window.screen.height)
    });


    return { width, height }
}