import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Portal, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { changePassword } from '../../../../redux/api/provider';
import { SnackBarGeneric } from '../../../common/SnackBarGeneric';
import { useSnackBar } from '../../../../hooks/useSnackBar';

export default function DialogPassword(props) {

    const initialForm = {
        password: '',
        password2: ''
    }
    const { open, handleClose } = props

    const [form, setForm] = React.useState(initialForm)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        const body = {
            "password": form.password
        }
        if (form.password === form.password2) {
            if (form.password.length > 7) {
                const request = await changePassword(body)
                if (request.error) {
                    openSnackBar(
                        "error",
                        "¡Ocurrio un error!"
                    );
                }
                else {
                    setForm(initialForm)
                    openSnackBar(
                        "success",
                        "¡Se actualizo con exito!"
                    );
                }
            }
            else {
                openSnackBar(
                    "warning",
                    "¡La contraseña es corta!, mínimo 8 dígitos"
                );
            }
        }
        else {
            openSnackBar(
                "warning",
                "¡No coinciden las contraseñas!"
            );
        }
    }

    return (
        <div>
            <DialogFake
                id="aca"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Cambiar su contraseña'}
                </DialogTitle>
                <DialogContent>
                    <Content>
                        <TextField inputProps={{ minLength: 7 }} onChange={handleChange} value={form.password} name="password" label="Contraseña" />
                        <TextField inputProps={{ minLength: 7 }} onChange={handleChange} value={form.password2} name="password2" label="Repetir contraseña" />
                    </Content>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit} autoFocus>
                        Cambiar
                    </Button>
                </DialogActions>
            </DialogFake>
            <Portal>
                <SnackBarGeneric
                    show={snackBar.show}
                    handleClose={closeSnackBar}
                    message={snackBar.message}
                    variant={snackBar.variant}
                />
            </Portal>
        </div>
    );
}


const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const DialogFake = styled(Dialog)`
    z-index: 2;
`