import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
// import Layout from "../components/shared/layout/Layout";

const PublicRoute = (props) => {
	const { component: Component, isAuthenticated, ...rest } = props;

	// RETURN
	return (
		<>
			<Route
				{...rest}
				render={(props) =>
					isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.auth.authToken,
});

export default connect(mapStateToProps)(PublicRoute);
