import axios from "axios";

export async function getSettlementsServices() {
	try {
		const req = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_liquidaciones`);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function getInfoSettlements(id) {
	try {
		const req = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_liquidaciones/${id}`);
		return req.data
	} catch (error) {
		return {error}
	}
}

export async function getOrdersService() {
	try {
        const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador_liquidaciones/ordenes_consumidas`);
        return response.data
    } catch (error) {
        return {error}
    }
}

export async function createLot(body) {
	try {
        const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/prestador_liquidaciones`, body);
        return response.data
    } catch (error) {
        return {error}
    }
}