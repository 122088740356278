import React, { useEffect, useState } from 'react'
import CardGeneric from '../../components/common/CardGeneric'
import SearchGeneric from '../../components/common/SearchGeneric'
import { SnackBarGeneric } from '../../components/common/SnackBarGeneric'
import TableGeneric from '../../components/common/TableGeneric'
import EventNoteIcon from '@material-ui/icons/EventNote';
import { useSnackBar } from '../../hooks/useSnackBar'
import PageLoading from '../../components/common/PageLoading'
import styled from 'styled-components'
import { getSettlementsServices } from '../../redux/api/settlements'
import DialogGeneric from '../../components/common/DialogGeneric'
import ListIcon from '@material-ui/icons/List';
import { ButtonBlue } from '../../components/common/ButtonsGenerics'
import InfoSettlements from '../../components/ui/organisms/settlements/InfoSettlements'
import { format } from '../../helpers/FormatDate.helper'

const Settlements = () => {

    const columns = [
        { id: 'fecha_presentacion', label: 'Fecha de presentación' },
        { id: 'año', label: 'Período ' },
        { id: 'total_liquidacion_string', label: 'Total a liquidar' },
        { id: 'estado_liquidacion', label: 'Estado' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const [settlements, setSettlements] = useState(null);
    const [filtered, setFiltered] = useState(null)
    const [closeDialog, setCloseDialog] = useState(false)
    const [show, setShow] = useState(false);

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    useEffect(() => {
        !settlements && getSettlements()
    }, [settlements]);

    const getSettlements = async () => {
        const request = await getSettlementsServices()
        if (request.error) {
            if (request?.error?.response?.status === 404) {
                openSnackBar(
                    "error",
                    "¡No existen subprestadores!"
                );
                tableRender([])
                return
            }
            else{
                console.log("else");
                openSnackBar(
                    "error",
                    "¡Error al traer los subprestadores!"
                );
                return
            }
        }
        else {
            tableRender(request)
        }
    }

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
    }

    const handleReset = () => {
        setShow(false)
        setSettlements(null)
    }

    const tableRender = providers => {
        let result = []
        if (providers) {
            result = providers.map(provider => {
                return {
                    ...provider,
                    fecha_presentacion: format(provider.fecha_presentacion),
                    total_liquidacion_string: `$${parseFloat(provider?.total_liquidacion, 10).toFixed(2)}`,
                    acciones: <Actions>
                        <DialogGeneric handleForceClose={handleForceClose} close={closeDialog} button={<ButtonBlue><ListIcon/></ButtonBlue>} component={<InfoSettlements id={provider.id}/>}/>
                    </Actions>,
                }
            })
            
        }
        setSettlements(result.reverse())
        return result
    }

    const handleValues = (filtered) => {
        setFiltered(filtered)
    }

    if(!settlements) {
        return <>
            <PageLoading w="100%" h="100px" />
            <SnackBarGeneric
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </>
    }

    return (
    <CardGeneric title={"Lotes"} icon={<EventNoteIcon />}>
        <SearchGeneric title="Buscar liquidacion" handleValues={handleValues} filterNames={['fecha_presentacion', 'año', 'total_liquidacion', 'estado_liquidacion']} values={settlements} />
        <TableGeneric columns={columns} rows={filtered || settlements} pageSize={10} />
        
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </CardGeneric>
    )
}

export default Settlements

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`