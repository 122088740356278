import React from "react";
import { Redirect, Switch } from "react-router-dom"
import ConsumptionHistory from "./pages/pharmacy/ConsumptionHistory";
import Contact from "./pages/contact/Contact";
import Login from "./pages/auth/Login";
import Orders from "./pages/orders/Orders";
import Shifts from "./pages/shifts/Shifts";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import StockPharmacy from "./pages/pharmacy/StockPharmacy";
import Audit from "./pages/audit/Audit";
import Logout from "./pages/auth/Logout";
import CurrentDebt from "./pages/conventions/CurrentDebt";
import RequestPharmacy from "./components/templates/pharmacy/RequestPharmacy";
import Conventions from "./pages/conventions/Conventions";
import Subprovider from "./pages/subprovider/Subprovider";
import Provider from "./pages/providers/Provider";
import Settlements from "./pages/settlements/Settlements";
import NewLot from "./pages/settlements/NewLot";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordToken from "./pages/auth/ResetPasswordToken";


const Routes = () => {
    return (
        <Switch>
            {/* Rutas privadas */}
            <PrivateRoute path="/provider" exact component={Provider} />
            <PrivateRoute path="/shifts" exact component={Shifts} />
            <PrivateRoute path="/orders" exact component={Orders} />
            <PrivateRoute path="/request-pharmacy" exact component={RequestPharmacy} />
            <PrivateRoute path="/consumption-history" exact component={ConsumptionHistory} />
            <PrivateRoute path="/stock-medicines" exact component={StockPharmacy} />
            <PrivateRoute path="/audit" exact component={Audit} />
            <PrivateRoute path="/conventions" exact component={Conventions} />
            <PrivateRoute path="/current-debt" exact component={CurrentDebt} />
            <PrivateRoute path="/contact" exact component={Contact} />
            <PrivateRoute path="/auth/logout" exact component={Logout} />
            <PrivateRoute path="/sub-provider" exact component={Subprovider} />
            <PrivateRoute path="/settlements" exact component={Settlements} />
            <PrivateRoute path="/new-settlements" exact component={NewLot} />

            {/* Rutas publicas */}
            <PublicRoute path="/auth/login" exact component={Login} />
            <PublicRoute path="/auth/reset" exact component={ResetPassword} />
            <PublicRoute path="/auth/reset/:token" exact component={ResetPasswordToken} />

            {/* Redirecciones */}
            <Redirect path="/" to="/provider" />
            <Redirect path="*" to="/not-found" />
        </Switch>
    )
}

export default Routes;