import axios from "axios";

export async function searchMedicines({criterion, search}) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/medicamento/search?busqueda=${search.toLowerCase()}&critrio=${criterion}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getPharmacyByDate(params) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/public/orden_de_medicamentos/search?afiliado_id=${params.affiliate}&fecha_desde=${params.initial}&fecha_hasta=${params.finish}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getPharmacyById(id) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/public/orden_de_medicamentos/${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createPharmacyOrder(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/orden_de_medicamentos/`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}