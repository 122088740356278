import React from 'react';
import styled from 'styled-components';
import CardGeneric from '../../common/CardGeneric';
import PharmacyForm from '../../ui/organisms/pharmacy/PharmacyForm';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

const RequestPharmacy = props => {

    return <RequestPharmacyWrapper>
        <CardGeneric icon={<CreateNewFolderIcon/>} title="Solicitar pedido farmacia">
            <PharmacyForm/>
        </CardGeneric>
    </RequestPharmacyWrapper>
}

export default RequestPharmacy;

const RequestPharmacyWrapper = styled.div`
    width: 100%;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;  
    }
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
`