import React, { useState } from "react";
import styled from "styled-components";
import { ButtonTransparent } from "../../common/ButtonsGenerics";
import CardGeneric from "../../common/CardGeneric";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import OrderForm from "../../ui/organisms/orders/OrderForm";


const TakeOrders = props => {
    const { handleBackPage, handleRefresh } = props

    return <TakeOrdersWrapper>
        <ArrowBackContainer>
            <ButtonTransparent onClick={handleBackPage}><ArrowBackIcon/><NameButton>Atras</NameButton></ButtonTransparent>
        </ArrowBackContainer>
        <CardGeneric icon={<SearchIcon/>} title="Emitir orden nueva">
            <OrderForm handleRefresh={handleRefresh}/>
        </CardGeneric>
    </TakeOrdersWrapper>
}

export default TakeOrders

const TakeOrdersWrapper = styled.div`
    width: 100%;
`

const ArrowBackContainer = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NameButton = styled.div`
    margin-left: 1rem;
    font-size: 1rem;
`