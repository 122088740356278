import { ButtonBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../helpers/currency.format.helper';
import { getPharmacyById } from '../../../redux/api/phamarcy';
import ErrorPage from '../../common/ErrorPage';
import PageLoading from '../../common/PageLoading';
import { Text, Span } from '../../common/Text';
// import { getOrderById } from '../../../../redux/api/orders';

const InfoHistory = props => {
    const { id, handleClose } = props

    const [information, setInformation] = useState(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (information === null) {
            getInfo(id)
        }
    }, [information, id])

    const getInfo = async (id) => {
        const request = await getPharmacyById(id)
        if (request.error) {
            setError(true)
            return
        }
        setInformation(request)
    }

    if (error === true) {
        return <ErrorPage message="No se pudo obtener la información de esta orden" />
    }

    if (information === null) {
        return <PageLoading w="400px" h="200px" />
    }

    console.log(JSON.parse(information?.medicamentos));

    return <InfoOrderWrapper>
        <Header>
            <Text color="#333333" fontSize="1.7rem" fontWeight="600">Detalle de orden</Text>
            <ButtonBase onClick={handleClose}><Text color="#888888" fontSize="1rem" fontWeight="700">X</Text></ButtonBase>
        </Header>
        <Body>
            <Grid col="4">
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Fecha</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information?.fecha_solicitud}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">N° Orden</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information?.id}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Médico solicitante</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">{information?.medico_solicitante !== "False" ? information?.medico_solicitante : "No posee"}</Text>
                </div>
                <div>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Tipo de movimiento</Text>
                    <Text color="#333333" fontSize="1rem" fontWeight="600">VENTA</Text>
                </div>
            </Grid>
            {/* <Grid col="1">
                <List>
                    <Text color="#333333" fontSize="1rem" fontWeight="700">Medicación</Text>
                    <Grid col="6">
                        <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Medicamento</Text></Th>
                        <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Cantidad</Text></Th>
                        <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Precio unitario</Text></Th>
                        <Th><Text color="#333333" fontSize="1rem" fontWeight="700">Total</Text></Th>
                        <Th><Text color="#333333" fontSize="1rem" fontWeight="700">%Descuento</Text></Th>
                        <Th><Text color="#333333" fontSize="1rem" fontWeight="700">A abonar</Text></Th>

                        {JSON.parse(information?.medicamentos).map(item => (<>
                            <Text color="#333333" fontSize="1rem" fontWeight="600">{item.name}</Text>
                            <Text color="#333333" fontSize="1rem" fontWeight="600">{item.cantidad}</Text>
                            <Text color="#333333" fontSize="1rem" fontWeight="600"></Text>
                            <Text color="#333333" fontSize="1rem" fontWeight="600"></Text>
                            <Text color="#333333" fontSize="1rem" fontWeight="600"></Text>
                            <Text color="#333333" fontSize="1rem" fontWeight="600"></Text>
                        </>))}
                    </Grid>

                </List>

            </Grid> */}
            <Grid col="2">
                <Block>Importe</Block>
                <Right><Text fontSize="1.3rem" color="#222222">${formatCurrency(information?.importe_total?.toFixed(2))}</Text></Right>
                <Block>Descuento</Block>
                <Right><Text fontSize="1.3rem" color="#e34d4b">- ${formatCurrency(information?.importe_damsu?.toFixed(2))}</Text></Right>
                <Block>A Cargo del afiliado</Block>
                <Right><Text fontSize="1.6rem" color="#222222">${formatCurrency(information?.importe_a_abonar?.toFixed(2))}</Text></Right>
            </Grid>
        </Body>
    </InfoOrderWrapper>
}

export default InfoHistory

const InfoOrderWrapper = styled.div`
    width: auto;
    min-width: 400px;
`

const Header = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Body = styled.div`
    width: 100%;
    margin: 1rem 0;
`

const Grid = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(${p => p.col},1fr);
`

const Block = styled.div`
    width: 100%;
    /* max-width: 150px; */
    background-color: #7dbd6a;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin: .1rem 0;
`

const Right = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin: .1rem 0;
`

const Th = styled.div`
    width: 120px;
`

const List = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        width: 600px;
        overflow-x: auto;
    }
    @media (max-width: 468px) {
        width: 380px;
        overflow-x: auto;
    }
`