import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { utilsActions } from '../../../../redux/actions';
import { createSubProvider } from '../../../../redux/api/subprovider';
import { getTypeDocuments } from '../../../../redux/api/utils';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import PageLoading from '../../../common/PageLoading';
import { SnackBarGeneric } from '../../../common/SnackBarGeneric';
import { Text } from '../../../common/Text';

const CreateSubProvider = (props) => {

    const { handleForceClose, handleReset } = props

    const { user } = useSelector(state => state.auth)
    const specialties = useSelector(state => state.utils.specialties)
    const dispatch = useDispatch()

    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const [loading, setLoading] = useState(false)
    const [speciality, setSpeciality] = useState(null)
    const [typeDocument, setTypeDocument] = useState(null)
    const [form, setForm] = useState({
        id: user.prestador_id,
        name: "",
        last_name: "",
        dni: "",
        type_doc: null,
        matricula: "",
        speciality: "",
    });

    // Effects
    useEffect(() => {
        if (specialties === null) {
            dispatch(utilsActions.specialtiesRequest())
        }
    })

    useEffect(() => {
        !typeDocument && getDocuments()
    }, [])


    const getDocuments = async () => {
        const request = await getTypeDocuments()
        if (request.error) {

        }
        else {
            setTypeDocument(request)
        }
    }

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

    const handleSpecialties = (e, value) => {
        if (value !== null) {
            setSpeciality(value.name)
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        const body = {
            "nombre": form.name,
            "tipo_documento_id": form.type_doc,
            "apellido": form.last_name,
            "numero_documento": parseInt(form.dni, 10),
            "matricula": parseInt(form.matricula, 10),
            "especialidad": speciality
        }
        const request = await createSubProvider(body)
        setLoading(false)
        if (request.error) {
            openSnackBar(
                "error",
                "¡No se pudo crear el subprestador!"
            );
        }
        else {
            openSnackBar(
                "success",
                "¡Prestador creado con éxito!"
            );
            handleForceClose()
            setTimeout(() => {
                handleReset()
            }, 1200);
        }
    }

    return <FormWrapper>
        <Text color="#555555" fontWeight="bold" fontSize="1.2rem">Crear subprestador</Text>
        <div>
            <Grid>
                <TextField fullWidth value={form.name} name='name' onChange={handleChange} label="Nombre" />
                <TextField fullWidth value={form.last_name} name='last_name' onChange={handleChange} label="Apellido" />
            </Grid>
            <Grid>
                {!!typeDocument && (<FormControl fullWidth variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Tipo de doc.</InputLabel>
                    <Select
                        value={form.type_doc}
                        onChange={handleChange}
                        name="type_doc"
                    >
                        {typeDocument.map(item => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>)}
                <TextField fullWidth value={form.dni} name='dni' type="number" onChange={handleChange} label="Documento" />
            </Grid>
            <TextField fullWidth inputProps={{
                max: 10
            }} value={form.matricula} type="text" name='matricula' onChange={handleChange} label="Matricula" />
            <Autocomplete
                options={specialties}
                getOptionLabel={(option) => option.name}
                onChange={handleSpecialties}
                renderInput={(params) => <TextField {...params} value={speciality} label="Seleccione una especialidad" variant="standard" />}
            />
        </div>

        <Footer>
            <Button onClick={handleForceClose}>Cancelar</Button>
            {!loading ? <ButtonGreen
                disabled={(!form.name || !form.last_name || !form.matricula || !speciality || !form.type_doc || !form.dni) ? true : false}
                onClick={handleSubmit}>Crear subprestador</ButtonGreen> : <PageLoading h="60px" />}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </FormWrapper>;
};

export default CreateSubProvider;

const FormWrapper = styled.div`
    min-width: 350px;
    display: flex;
    flex-direction: column;
    >div{
        margin: 15px;
    }
`

const Footer = styled.div`
    padding: 0 15px;
    width: calc(100% - 30px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`