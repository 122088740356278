import { Badge } from "@material-ui/core";
import styled from "styled-components";

export const BadgeUI = styled(Badge)`
    font-size: ${p => p.fontSize};
    font-weight: bold;
    color: ${p => p.color};
    background-color: ${p => p.bg};
    padding:  .3rem .8rem;
    margin:  .5rem 0;
    border-radius: 15px;
`