import React, { useState } from "react";
import styled from "styled-components";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CardGeneric from "../../components/common/CardGeneric";
import FormPharmacyHistory from "../../components/templates/pharmacy/FormPharmacyHistory";
import TableGeneric from "../../components/common/TableGeneric";
import DialogGeneric from "../../components/common/DialogGeneric";
import { ButtonBlue } from "../../components/common/ButtonsGenerics";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import InfoHistory from "../../components/templates/pharmacy/InfoHistory";


const ConsumtionHistory = () => {

    const [result, setResult] = useState(null)
    const [show, setShow] = useState(false)
    const [closeDialog, setCloseDialog] = useState(false)

    const handleForceClose = () => {
        setCloseDialog(!closeDialog)
        setShow(false)
    }

    const handleResult = result => {
        setResult(result)
    }

    const renderTable = () => {
        if (result) {
            let resultFunc = []
            result.map((row) => {
                return resultFunc.push(
                    {
                        ...row,
                        type: "Venta",
                        acciones: <Actions>
                            <DialogGeneric close={closeDialog} handleForceClose={handleForceClose} button={<ButtonBlue><MoreVertSharpIcon /></ButtonBlue>} component={<InfoHistory handleClose={handleForceClose} id={row.id}/>}/>
                        </Actions>,
                    }
                )

            })
            return resultFunc
        }
        else {
            return []
        }
    }

    const columns = [
        { id: 'fecha_emision', label: 'N° Documento' },
        { id: 'id', label: 'N° Orden' },
        { id: 'importe_total', label: 'Importe Total' },
        { id: 'type', label: 'Tipo de movimiento'},
        { id: 'acciones', label: 'Acciones' },
    ]

    return <ConsumtionHistoryWrapper>
        <CardGeneric title={"Historial de Consumos"} icon={<SearchRoundedIcon/>}>
            <FormPharmacyHistory handleResult={handleResult}/>
        </CardGeneric>
        {result && (
            <CardGeneric title={"Resultados"} icon={<SearchRoundedIcon/>}>
                <TableGeneric columns={columns} rows={renderTable()} pageSize={10}/>
            </CardGeneric>
        )}
    </ConsumtionHistoryWrapper>
}

export default ConsumtionHistory;

const ConsumtionHistoryWrapper = styled.div`
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
`