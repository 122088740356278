import axios from "axios";

export async function sendMail(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/contacto/`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getContact() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/contacto/`);
		return response.data
	} catch (error) {
		return { error }
	}
}