import React from "react";
import { Redirect, Route } from "react-router-dom";

import Layout from "../components/shared/layout/Layout";
import { connect } from "react-redux";
// import Layout from "../components/shared/layout/Layout";

const PrivateRoute = (props) => {
	const { component: Component, isAuthenticated, ...rest } = props;

	// RETURN
	return (
		<Route
			{...rest}
			component={(props) =>
				isAuthenticated ? (
					<Layout>
						<Component {...props} />
					</Layout>
				) : (
					<Redirect to="/auth/login" />
				)
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.auth.authToken,
	user: state.auth.user,
});

export default connect(mapStateToProps)(PrivateRoute);
